@import "minima";

.footer-content {
    text-align: right;
}

.footer-links {
    float: left;
}

// Fancy project grid layout you've totally never seen before
.project-gridview {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.project {
  width: 250px;
  height: 190px !important;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0.5em;
  height: auto;
  overflow: hidden;
  box-shadow: 0 0px 6px 0 rgba(0,0,0,0.2);
  cursor: pointer;
}

.tagline {
  border-left: 5px solid #ccc;
  padding-left: 1em;
  font-size: 0.8em;
}

.image-card {
  margin: -1em -1em 0em -1em;
  width: auto;
  max-height: 120px;
  min-height: 120px;
  overflow: hidden;
  border-bottom: 1px solid #ccc;
}

.image-card img {
  min-height: 120px;
}

.title {
  font-weight: 600;
}

.project-gridview a {
  border: none !important;
}

.project-gridview .tagline {
  color: black !important;
}